import React from "react";
import Nav from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import { Grid, Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    backgroundColor: "rgb(80, 132, 140, 0.2)",
  },
  root: {
    minHeight: "84vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alertRoot: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  paperMessage: {
    marginBottom: "3rem",
    padding: "2rem",
    background: "rgb(237, 247, 237)",
  },
  paper: {
    padding: "2rem",
  },
  gridContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  typo: {
    marginTop: "1rem",
  },
}));

const Message = ({ type }) => {
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  switch (type) {
    // Email confirmed
    case "GPT7j5Nd4o":
      return (
        <div className={classes.alertRoot}>
          <Alert severity="success">
            <AlertTitle>Erfolgreich bestätigt</AlertTitle>
            Vielen Dank für Ihre Registrierung. Wir werden Sie in Kürze mit
            weiteren Informationen kontaktieren.
          </Alert>
        </div>
      );
    // Email already confirmed
    case "B0XCadHlAJ":
      return (
        <div className={classes.alertRoot}>
          <Alert severity="success">
            <AlertTitle>Bestätigt</AlertTitle>
            Die Email wurde bereits bestätigt.
          </Alert>
        </div>
      );
    // Error occured
    case "w8a2xTMDZJ":
      return (
        <div className={classes.alertRoot}>
          <Alert severity="error">
            <AlertTitle>Fehler</AlertTitle>
            Es tut uns leid. Ein Fehler ist aufgetreten.
          </Alert>
        </div>
      );
    default:
      return (
        <div className={classes.alertRoot}>
          <Alert severity="success">
            <AlertTitle>Bestätigung</AlertTitle>
            Um die Anfrage abzuschließen, bestätigen Sie bitte Ihre Email.
          </Alert>
        </div>
      );
  }
};

const ThankYou = ({ location }) => {
  const classes = useStyles();
  const msgType = new URLSearchParams(location.search).get("msg");

  return (
    <div className={classes.mainRoot}>
      <Nav />
      <Box className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={6}>
            <Paper elevation={4} className={classes.paperMessage}>
              <Message type={msgType} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
};

export default ThankYou;
